import { Component, OnInit, HostListener, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jones-navbar',
  templateUrl: './jones-navbar.component.html',
  styleUrls: ['./jones-navbar.component.scss']
})
export class JonesNavbarComponent implements OnInit {

  public isMenuCollapsed = true;

  public visible = true;

  constructor(public router:Router) { }

  ngOnInit(): void {
    console.log(this.isMenuCollapsed);
  }
  
  
  navFunction(){
    this.visible = !this.visible; 
  }

}
