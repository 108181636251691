import { Component, OnInit, Input } from '@angular/core';
import { IcontactInfo } from '../../models/icontact-info';
import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor() { }
  @Input() contact: IcontactInfo = {
  id: 1,
  icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg" ,
  firstName: "mark2222",
  lastName: "lastname2222",
  title: "stem2222",
  locationName: "",
  postion: "leader2222",
  address: "123 w street2222",
  state: "CA222",
  city: "Stockton222",
  zip: 952000222,
  phoneNumber: "209-465-5665222",
  email: "email@Email.com22222",
  faxNumber: ''
  }



  ngOnInit(): void {
    // console.log(this.contact);
  }

}
