<div class="row">
  <div class="col">
    <h1 class="white sub-title">Volunteer With Us</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="white">Volunteer to lead our field trip programs! We are looking for retired outdoor enthusiasts,
      high school and college age students that love working with students.</p>
  </div>
</div>
<div class="row">
  <div class="col">
    <!-- <p style="color: #C""BF1FF;text-decoration: underline #CBF1FF;">Become a Volunteer at Durham Ferry!</p> -->
    <a class="front" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScCDHnjssTNAllHwCWjZN2aKV-MA-Uw-d7s5wnKHyR0viW35Q/viewform">Become
      a Volunteer at Durham Ferry!</a>
  </div>
</div>