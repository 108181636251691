<div class="container d-flex text-center justify-content-center  mt-5">
    <nav class="navbar navbar-expand-lg fix-width fix-navbar px-0">
        <a class="" href="/Home"><img src="assets/images/Group112.svg" style="width: 100%;" alt="SJCOE Logo"></a>
        <button class="navbar-toggler bg-light" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>

        <div class="navbar-collapse collapse " [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active space-nav-jones">
                    <a class="nav-link nav-white" href="#">Programs</a>
                </li>
                <li class="nav-item space-nav-jones">
                    <a class="nav-link nav-white" href="#">Teachers</a>
                </li>
                <li class="nav-item space-nav-jones">
                    <a class="nav-link nav-white" href="#">Parents</a>
                </li>
                <li class="nav-item space-nav-jones">
                    <a class="nav-link nav-white" href="#">About Us</a>
                </li>
                <ul ngbDropdown class="d-inline-block pt-2">
                    <div ngbDropdown class="dropdown">
                        <button class="btn btn-secondary" id="dropdownMenuButton" ngbDropdownToggle><i>Choose an
                                Academy</i></button>
                        <div class="dropdown-content">
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic2"
                                class="dropdown-menu dropdown-menu-right mx-0">
                                <li>
                                    <a href="/DurhamFerry"><button class="dropdownBtn-color" ngbDropdownItem>Durham
                                            Ferry</button></a>
                                </li>
                                <li>
                                    <a href="/JonesGulch"><button class="dropdownBtn-color" ngbDropdownItem>Jones
                                            Gulch</button></a>
                                </li>
                                <li>
                                    <a href="/SkyMountain"><button class="dropdownBtn-color" ngbDropdownItem>Sky
                                            Mountain</button></a>
                                </li>
                            </div>
                        </div>
                    </div>
                </ul>
            </ul>
        </div>
    </nav>
</div>
