import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goToHome(){
    this.router.navigate([`Home`]);
  }
  goToSkyMountain(){
    this.router.navigate([`SkyMountain`]);
  }
  goToDurhamFerry(){
    this.router.navigate([`DurhamFerry`]);
  }

}
