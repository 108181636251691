<div class="container text-center">
  <div class="row mt-5 ">
    <div class="col text-center">
      <h3 class="sub-title">HISTORY</h3>
    </div>
  </div>
  <div class="row">
    <div class="col text-left">
      <p class="desktop">
        Durham Ferry has, throughout its history,
        been an important site for people of the surrounding region.
        Our site lies within the ancestral homelands of the Yokuts native Californian people.
        In the mid-1800s it was the location of a ferryboat crossing, where pioneers and hopeful explorers in search
        of gold, land, and adventure crossed the great San Joaquin River.
        From 1971-1980, the land was a county-operated park called South County Regional Park, and served as a place
        for families and friends to picnic, ride horses, fish, and enjoy the outdoors. In 1980, the property changed
        hands to the state of California, and it became known as Durham Ferry State Recreation Area. Finally in 1997,
        when state and county funds didn't suffice to manage the park, the land was given to SJCOE for educational
        purposes. Since 1999, it has been owned and operated by SJCOE. The land is also currently home to
        Durham Ferry Academy, a charter school within the Venture Academy Family of Schools. For information about
        Durham Ferry Academy,
        you can visit the Venture Academy <a href="https://www.ventureacademyca.org/middle-school-academies.html"
          target="_blank">middle school</a> and <a href="https://www.ventureacademyca.org/high-school-academies.html"
          target="_blank">high school</a> academies website.

      </p>
      <p class="mobile">
        Durham Ferry has, throughout its history,
        been an important site for people of the surrounding region.
        Our site lies within the ancestral homelands of the Yokuts native Californian people.
        In the mid-1800s it was the location of a ferryboat crossing, where pioneers and hopeful explorers in search
        of gold, land, and adventure crossed the great San Joaquin River. <a (click)="readMore()" id="readLess"
          aria-label="Click to read more on the history of Durham Ferry">Read More...</a>
        <span id="readMore">From 1971-1980, the land was a county-operated park called South County Regional Park, and
          served as a place
          for families and friends to picnic, ride horses, fish, and enjoy the outdoors. In 1980, the property changed
          hands to the state of California, and it became known as Durham Ferry State Recreation Area. Finally in 1997,
          when state and county funds didn't suffice to manage the park, the land was given to SJCOE for educational
          purposes. Since 1999, it has been owned and operated by SJCOE. The land is also currently home to
          Durham Ferry Academy, a charter school within the Venture Academy Family of Schools. For information about
          Durham Ferry Academy,
          you can visit the Venture Academy <a class="underline"
            href="https://www.ventureacademyca.org/middle-school-academies.html" target="_blank">middle school</a> and
          <a class="underline" href="https://www.ventureacademyca.org/high-school-academies.html" target="_blank">high
            school</a> academies website.
        </span>

      </p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col ">
      <h3 class="sub-title text-center">Ecology</h3>
      <p class="text-left ">Durham Ferry is home to an incredible diversity of wildlife, including several large
        mammals, many reptiles and amphibians, insects, and over 200 species of birds. It is also one of the best
        places to encounter native plants of our area. Check out all of our residents with fur, feathers, scales,
        fins, wings, roots, and spores by visiting our iNaturalist Project, where visitors can record observations of
        wildlife they see and contribute to a worldwide Citizen Science database. You can also learn about our local
        plants and animals by browsing these <a class="underline" href="https://www.inaturalist.org/"
          target="_blank">iNaturalist</a> wildlife guides of Durham Ferry.</p>
    </div>
  </div>
</div>