

export const contactLookup = {
    contactHomePage: [
        {
            id: 0,
            icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
            firstName: "Tamara",
            lastName: "Basepayne",
            title: "",
            locationName: "" ,
            postion: "Environmental Literacy",
            address: "",
            state: "",
            city: "",
            zip: 0,
            phoneNumber: "(209) 817 - 8053",
            email: "tbasepayne@sjcoe.net",
            faxNumber: ''

        },
        {
            id: 1,
            icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
            firstName: "Lissa",
            lastName: "Gilmore",
            title: '',
            locationName: '',
            postion: 'Sky Mountain<br> Outdoor Ed and Environmental Literacy',
            address: '',
            state: '',
            city: '',
            zip: 0,
            phoneNumber: "(530) 389 - 2008",
            email: "lgilmore@sjcoe.net",
            faxNumber: ''
        }
        // {
        //     id: 2,
        //     icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
        //     firstName: "Erik",
        //     lastName: "Forsman",
        //     title: '',
        //     locationName: '',
        //     postion: 'Sky Mountain Outdoor Education Center',
        //     address: '',
        //     state: '',
        //     city: '',
        //     zip: 0,
        //     phoneNumber: "(530) 389 - 2001",
        //     email: "eforsman@sjcoe.net",
        //     faxNumber: ''
        // }
    ],
    contactJoneGulch: [
        {
            id: 0,
            icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
            firstName: "Lissa",
            lastName: "Gilmore",
            title: "",
            locationName: "Outdoor Education, Jones Gulch",
            postion: "Coordinator",
            address: "",
            state: "",
            city: "",
            zip: 0,
            phoneNumber: "",
            email: "lgilmore@sjcoe.net",
            faxNumber: ''

        },
        {
            id: 1,
            icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
            firstName: "Amy",
            lastName: "Daniels",
            title: "Jones Gulch",
            locationName: "Outdoor Education, ",
            postion: "Administrstive Assistabt,",
            address: "P.O. Box 213030",
            state: "CA",
            city: "Stockton",
            zip: 95213,
            phoneNumber: "(209) 468-4809",
            email: "adaniels@sjcoe.net",
            faxNumber: ''

        },
        {
            id: 2,
            icon: "../../../assets/images/iconfinder_icon-map_211858.svg",
            firstName: "Allison",
            lastName: "Collins",
            title: "",
            locationName: "Camp Jones Gulch",
            postion: "Principal",
            address: "11000 Pescadero Road",
            state: "CA",
            city: "La Honda",
            zip: 94020,
            phoneNumber: "(650)747-0129",
            email: "email@Email.com44444",
            faxNumber: 'fax (650)747-1523'

        }
    ],
    contactDurhamFerry: [

            {
                id: 0,
                icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
                firstName: "Lissa",
                lastName: "Gilmore",
                title: '',
                locationName: '',
                postion: 'Outdoor Education and <br> Environmental Literacy Coordinator',
                address: '',
                state: '',
                city: '',
                zip: 0,
                phoneNumber: "(530) 389 - 2008",
                email: "lgilmore@sjcoe.net",
                faxNumber: ''
            },
            {
                id: 1,
                icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
                firstName: "Kristine",
                lastName: "Stepping",
                title: "",
                locationName: "" ,
                postion: "Outdoor Education and <br> Environmental Literacy <br> Coordinator",
                address: "",
                state: "",
                city: "",
                zip: 0,
                phoneNumber: "(209) 292 - 2715",
              email: "kstepping@sjcoe.net",
                faxNumber: ''
    
            },
            {
                id: 2,
                icon: "../../../assets/images/iconfinder_icon-map_211858.svg",
                firstName: "",
                lastName: "",
                title: "Address:",
                locationName: "Durham Ferry Outdoor Education Center" ,
                postion: "",
                address: "29442 Airport Way",
                state: "CA",
                city: "Manteca",
                zip: 95337,
                phoneNumber: "",
                email: "",
                faxNumber: ''
    
            },
            {
                id: 3,
                icon: "../../../assets/images/iconfinder_icon-map_211858.svg",
                firstName: "",
                lastName: "",
                title: "STEM Programs",
                locationName: '',
                postion: "",
                address: "PO Box 213030",
                state: "CA",
                city: "Stockton",
                zip: 95213,
                phoneNumber: "(209) 468 - 4880",
                email: "",
                faxNumber: ''
    
            }
    ],
    contactSkyMountain: [{
        id: 0,
        icon: "assets/images/iconfinder_ic_contact_phone_48px_3669371.svg",
        firstName: "Firstname",
        lastName: "Lastname",
        title: "",
        locationName: "",
        postion: "Outdoor Education and <br> Environmental Literacy <br> Coordinator",
        address: "",
        state: "",
        city: "",
        zip: 0,
        phoneNumber: "(209) 123-4567",
        email: "email@sjcoe.net",
        faxNumber: ''
    },
    {
        id: 1,
        icon: "../../../assets/images/iconfinder_icon-map_211858.svg",
        firstName: "",
        lastName: "",
        title: "Address:",
        locationName: "Site Adress:",
        postion: "",
        address: "45600 Lake valley Drive",
        state: "CA",
        city: "Emigrant gap",
        zip: 95715,
        phoneNumber: "",
        email: "",
        faxNumber: ''
    }
    
]
    
}


