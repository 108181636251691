<!-- Underneath is a test -->

<div class="container d-flex text-center justify-content-center px-0 pt-3">
    <nav class="navbar navbar-expand-lg  fix-width fix-navbar px-0">
        <a class="" href="/Home"><img src="assets/images/Outdoor-Logo-B.png"  alt="SJCOE Logo"></a>
        <button class="navbar-toggler bg-light" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>
        <div [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}" class="super-nav navbar-collapse collapse">
            <span class="pointer super-x" (click)="isMenuCollapsed = !isMenuCollapsed">
                <p class="xSpot"><fa-icon [icon]="faTimes" style="font-size: 50px; color: #fff;" alt="Close Menu"></fa-icon></p>
            </span>
            <ul class="navbar-nav ml-auto">
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic1" ngbDropdownToggle>About</a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">

                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/History">History</a>
                        </li>
                        <!-- <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Smoec-Board">SMOEC Board</a>
                        </li> -->
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Staff">Staff</a>
                        </li>
                        <!-- <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Accreditations">Accreditations</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Faq">FAQ</a>
                        </li> -->
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Driving-Direct">Directions</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Photos-Vids">Photos/Video</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem target="_blank" class="menuItem" href="https://www.sjcoescience.org/">About SJCOE</a>
                        </li>
                    </div>
                </ul>
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" ngbDropdownToggle>Outdoor Education</a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu ">
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Grade-Science">5-6th Grade Science Camp</a>
                        </li>
                        <!-- <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Grade-Snow">5-6th Grade Snow Camp</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Teacher-Info">Teacher Info</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Cabin-Leader">Cabin Leader Info</a>
                        </li> -->
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Student-Packing">Student Packing List</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Family-Handbook">Family Handbook</a>
                        </li>
                        <!-- <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Educator-Prof">Educator Professional
                                Develoement</a>
                        </li> -->
                        <!-- <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Covid-19">Covid-19 Plan</a>
                        </li> -->
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Register-Science">Registration for Science Camp</a>
                        </li>
                    </div>
                </ul>
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" href="/Private-Groups">Private Groups</a>
                   
                </ul>
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" target="_blank" href="/Lodge" >Facilities</a>
                    
                </ul>

            

        <!--         <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" ngbDropdownToggle>Facilities</a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu">
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Map">Map</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Lodge">Lodge</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Cabins-Lodging">Cabins/Lodging</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Meeting-Spaces">Meeting Spaces</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Gym">Gym</a>
                        </li>
                    </div>
                </ul> -->
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home space-nav-home-contact">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" ngbDropdownToggle>Contact</a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu">
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Information">Information and Reservations</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem target="_blank" class="menuItem" href="https://www.edjoin.org/sjcoe">Employment Opportunities</a>
                        </li>
                    <!--     <li class="menuLi">
                            <a ngbDropdownItem class="menuItem" href="/Reservations">Reservations</a>
                        </li> -->
                    </div>
                </ul>

                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" target="_blank" id="dropdownBasic2" href="https://www.sjcoescience.org/" >SJCOE STEM Programs
                    </a>
                    
                </ul>
 <!--                <ul class="d-inline-block pt-2">
                    <div ngbDropdown class="dropdown">
                        <button class="btn btn-secondary programBtn" id="dropdownMenuButton" ngbDropdownToggle><i>Choose a Program</i></button>
                        <div class="dropdown-content">
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic2"
                                class="academy-menu dropdown-menu-right mx-0">
                                <li>
                                    <a href="/DurhamFerry"><button class="dropdownBtn-color" ngbDropdownItem>Durham
                                            Ferry</button></a>
                                </li>
                                <li>
                                    <a href="/SkyMountain"><button class="dropdownBtn-color" ngbDropdownItem>Sky
                                            Mountain</button></a>
                                </li>
                            </div>
                        </div>
                    </div>
                </ul> -->
            </ul>
        </div>
    </nav>
</div>