<div class="row mb-5" *ngIf="this.durhamTripsDisplay?.length > 0 ">
    <div class="col">
        <h1 class="white sub-title">Field Trips</h1>
    </div>
</div>
<div class="row">
    <div class="cardspace" style="display: flex;justify-content: center;gap: 15px;width: 100%;flex-wrap: wrap;">
        <div *ngFor="let event of this.durhamTripsDisplay" class="fcard mt-3">
            <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}" aria-label="click image to visit {{event.title_Short}}">
                <p class="invisibleText">click image to visit {{event.title_Short}}</p>
                <div class="card-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}"></div>
            </a>
            <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                <p class="invisibleText">click image to visit {{event.title_Short}}</p>
                <div class="card-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}"></div>
            </a>
            <div class="fcard-body d-flex flex-column card-body-Activities">
                <p class="sub-title2 text-left">{{event.title_Short}}</p>
                <p class="text-left">{{event.description_Short}}</p>
       
            </div><!-- end card body -->
            <div class="cardFooter">
                <div class="col text-placement pl-0">
                    <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}">
                        <button class="my-Btn py-1 mt-auto" style="border: none;">
                            <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                                Learn More
                                <fa-icon [icon]="faAngelRight" transform="up-1"
                                    style="font-size: 20px;color: white;" class="mx-2">
                                </fa-icon>
                            </span>
                        </button>
                    </a>
                    <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                        <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                            Learn More
                            <fa-icon [icon]="faAngelRight" transform="up-1"
                                style="font-size: 20px;color: white;" class="mx-2">
                            </fa-icon>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>