<div class="row">
    <div class="col-sm-12">
        <!-- <button type="button" (click)="goToDetails()" class="btn customBtn"
        style="padding-right: 40px; margin-top: 15px;">
        <i class="fas fa-chevron-left" style="padding-right: 15px;"></i>
        Back</button> -->
        <button type="button" (click)="goToHome()" class="btn customBtn">
            <i class="fas fa-chevron-left" style="padding-right: 15px;"></i>
            Home</button>
        <button type="button" (click)="goToSkyMountain()" class="btn skyMountainBtn">
            <i class="fas fa-chevron-left" style="padding-right: 15px;"></i>
            Sky Mountain</button>
        <button type="button" (click)="goToDurhamFerry()" class="btn durhamBtn">
            <i class="fas fa-chevron-left" style="padding-right: 15px;"></i>
            Durham Ferry</button>
    </div>
</div>