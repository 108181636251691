import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IEvent } from '../../models/ievent';
import { EventdataService } from '../../services/eventdata.service';

import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-durham-field-trips',
  templateUrl: './durham-field-trips.component.html',
  styleUrls: ['./durham-field-trips.component.scss']
})
export class DurhamFieldTripsComponent implements OnInit {
  durhamTripsArr: IEvent[] = [];
  durhamTripsNonshowcase: IEvent[] = [];
  durhamTripsShowcase: IEvent[] = [];
  durhamTripsDisplay: IEvent[] = [];
  faAngelRight = faAngleRight;

  constructor(public router: Router, private _eventservice: EventdataService) { }

  ngOnInit(): void {
    this.getDurhamFieldTrips();
  }
  getDurhamFieldTrips() {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.durhamTripsArr = res
        .filter(event => event.section_Student_durham === true && event.section_Durham_Trip === true && event.published === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.durhamTripsShowcase = this.durhamTripsArr.filter(event => event.isShowcased === true);
      this.durhamTripsNonshowcase = this.durhamTripsArr.filter(event => event.isShowcased === false);
      this.durhamTripsDisplay = this.durhamTripsShowcase.concat(this.durhamTripsNonshowcase);
      console.log(this.durhamTripsDisplay);

    });
  }
  toEvent(eventCard: IEvent): void {
    eventCard.internal_event ? this.router.navigate(['/Event', eventCard.eventId]) : window.open(eventCard.description_Long, "_blank");
    // if (eventCard.internal_event === true) {
    //   this.router.navigate(['/Event', eventCard.eventId]);
    // } else if (eventCard.internal_event === false) {
    //   // window.location.href = eventCard.description_Long;
    //   window.open(eventCard.description_Long, "_blank")
    // }
  }
}
