<div class="row text-center mb-5 mt-5">
  <div class="col mb-2 d-flex justify-content-center">
    <h2 class="font liteGray">Contact Us</h2><br>
  </div>
</div>


<div class="container">

  <div class="row justify-content-center">
    <!-- start contact container -->
    <div class="font-roboto col-sm-12 col-md-5 foot-deets footer-contact">

      <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
      <div class="footer-contact-text">
        <p>
          <b>Lissa Gilmore</b><br><span> Principal<br> Outdoor Ed and Environmental Literacy <br> Sky Mountain</span><br>
           <a href="mailto:lgilmore@sjcoe.net">lgilmore@sjcoe.net</a> 
        </p>
      </div>


    </div>

    <div class="font-roboto col-sm-12 col-md-5 foot-deets footer-contact">

      <img alt="Contact Icon" src="../../../assets/images/iconfinder_icon-map_211858.svg"><br>
      <div class="footer-contact-text">
        <p><b>Address:</b><br>45700 Six Mile Valley Rd<br>Emigrant Gap CA 95715</p>
      </div>

    </div>

    <div class="font-roboto col-sm-12 col-md-2 foot-deets footer-contact">
      <img alt="Contact Icon" src="../../../assets/images/iconfinder_icon-map_211858.svg"><br>
      <div class="footer-contact-text">
        <p><b>STEM Programs</b><br>PO Box 213030<br> Stockton CA 95213 <br><a href="tel:2094684880">(209) 468-4880</a></p>

      </div>

    </div>

  </div> <!-- end contact container -->
  <!-- docial icons -->

  <div class="row mt-2">
    <div class="col d-flex justify-content-center iconGap">
      <a href="https://twitter.com/sjcoestem" target="_blank" rel="noopener" aria-label="twitter icon">
        <fa-icon [icon]="faTwitter" style="font-size: 37px; color: #165884;" alt="twitter icon"></fa-icon>
      </a>
      <a href="https://www.facebook.com/SJCOESTEM" target="_blank" rel="noopener" aria-label="faceBook icon">
        <fa-icon [icon]="faFacebookF" style="font-size: 32px; color: #165884;" alt="faceBook icon">
        </fa-icon>
      </a>

    </div>
  </div>

</div><!-- end container -->

<div class="col text-center">
  <a href="https://www.sjcoe.org/" target="_blank">
    <img src="assets/images/SJCOE-Formal.png" style="width:400px;">
  </a>

</div>

<div class="footer">
  <img src="assets/images/FooterBackground-01.png" alt="FooterBackground" style="width: 100%;">
</div>
