<div *ngIf="this.router.url.includes('/SkyMountain')" class="col d-flex justify-content-center mt-5">
  <div class="card" style="width: 18rem; padding: 0%;">
      <img [src]="card.img" class="card-img-top" [alt]="card.alt">
      <div class="card-body">
          <p class="card-text">{{card.text}}</p>
      </div>
  </div>
</div>

<div class="container">
  <div class="row">
      <div class="cardspace" style="display: flex;justify-content: center;gap: 15px;width: 100%;flex-wrap: wrap;">
          <div *ngFor="let event of this.displayedEventCards" class="fcard mt-3">
              <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}">
                  <div class="card-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}"></div>
              </a>
              <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                  <div class="card-img-top" [ngStyle]="{'background-image': 'url('+event.images+')'}"></div>
              </a>
              <div class="fcard-body d-flex flex-column card-body-Activities">
                  <p class="sub-title text-left" style="font-size: 19px;">{{event.title_Short}}</p>
                  <p class="text-left">{{event.description_Short}}</p>
         
              </div><!-- end card body -->
              <div class="cardFooter">
                  <div class="col text-placement pl-0">
                      <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}">
                          <button class="my-Btn py-1 mt-auto" style="border: none;">
                              <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                                  Learn More
                                  <fa-icon [icon]="faAngelRight" transform="up-1"
                                      style="font-size: 20px;color: white;" class="mx-2">
                                  </fa-icon>
                              </span>
                          </button>
                      </a>
                      <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                          <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                              Learn More
                              <fa-icon [icon]="faAngelRight" transform="up-1"
                                  style="font-size: 20px;color: white;" class="mx-2">
                              </fa-icon>
                          </span>
                      </a>
                  </div>
              </div><!-- end card footer -->
          </div>
      </div>

  </div>
</div>