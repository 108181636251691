import { Component, OnInit, HostListener, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sky-navbar',
  templateUrl: './sky-navbar.component.html',
  styleUrls: ['./sky-navbar.component.scss']
})
export class SkyNavbarComponent implements OnInit {
  public isMenuCollapsed = true;
  faTimes = faTimes;

  public visible = true;

  constructor(public router:Router) { }

  ngOnInit(): void {
    console.log(this.isMenuCollapsed);
  }
  
  
  navFunction(){
    this.visible = !this.visible; 
  }

}
