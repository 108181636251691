<h2 class="white mb-5 sub-title" *ngIf="durhamProEventsArr?.length > 0 ">Professional Development</h2>
<div  class="row mt-5 justify-content-center">
    <!-- <div class="col-12 justify-content-center"> -->
        <div *ngFor="let event of durhamProEventsArr" class="  mb-3  pro-card" >
            <div class="row no-gutters row-fix">
                <div class="col-md-10   d-flex main-card-body card-border bg-white">
                    <div class="lcard-body">
                        <h3 class="fix-text-left text-left  liteGray font-Barbieri">{{event.title_Long}}</h3>
                        <p class="fix-text-left card-text text-left small-font desc-short" style="color: #000000 !important;">{{event.description_Short}}</p>
                                     <!-- start button -->
                    <div class="col text-placement fix-text-left pl-0 long-btn">
                        <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}">
                            <button class="my-Btn" style="border: none;">
                                <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                                    Learn more
                                    <fa-icon transform="up-1" [icon]="faAngelRight" style="font-size: 20px;color: white;" class="mx-2"></fa-icon>
                                </span>
                                <!-- <span class="d-flex shortText" style="padding-left: 10px; height: 25px;">
                                    Learn more about...
                                    <fa-icon transform="up-1" [icon]="faAngelRight" style="font-size: 20px;color: white;" class="ml-2"></fa-icon>
                                </span> -->
                            </button>
                        </a>
                        <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                            <span class="d-flex FullText" style="padding-left: 10px; height: 25px;">
                                Learn more
                                <fa-icon transform="up-1" [icon]="faAngelRight" style="font-size: 20px;color: white;" class="mx-2"></fa-icon>
                            </span>
                        </a>
                    </div><!-- end button -->
                        
                    </div>
       
                </div>
                <!-- Image -->
                <div class="col-md-1">
                    <a *ngIf="event.internal_event === true" href="Event-page/{{event.eventId}}">
                        <p class="invisibleText">click image to visit {{event.title_Short}}</p>
                        <div class="card-Elcop img-border card-fix-img remove-img" [ngStyle]="{ 'background-image': 'url('+event.images+')' }" alt="Card Image Cap"></div>
                    </a>
                    <a target="_blank" *ngIf="event.internal_event === false" href="{{event.description_Long}}">
                        <p class="invisibleText">click image to visit {{event.title_Short}}</p>
                        <div class="card-Elcop img-border card-fix-img remove-img" [ngStyle]="{ 'background-image': 'url('+event.images+')' }" alt="Card Image Cap"></div>
                    </a>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>