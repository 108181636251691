import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';

@Component({
  selector: 'app-event-deskmap',
  templateUrl: './event-deskmap.component.html',
  styleUrls: ['./event-deskmap.component.scss']
})
export class EventDeskmapComponent implements AfterViewInit {
  private map;
  private map1;
  private map2;

  private initMap(): void {
    if(this.router.url.includes("Summer-play")){
      this.map = L.map('deskMap', {
        center: [ 37.69147807609545, -121.26438379361512],
        zoom: 13
      });
       
      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });
      
      tiles.addTo(this.map);
    }else if(this.router.url.includes("Field-meander")){
    
      // This Section is a test for two maps
      this.map1 = L.map('deskMap1', {
        center: [ 37.90757149255013, -121.22913024952783],
        zoom: 15
      });
      
      const tiles1 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }); 
      tiles1.addTo(this.map1);
    }else if(this.router.url.includes("Field-bio")){

      this.map2 = L.map('deskMap2', {
        center: [ 38.01055838696796, -121.33866169151784],
        zoom: 15
      });
      
      const tiles2 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }); 
      tiles2.addTo(this.map2);
    }
    else{}
  }

  constructor(public router: Router) { }

  ngAfterViewInit(): void {
    this.initMap();
  }

}
