<div class="row text-center mb-5 mt-5">
  <div class="col mb-2 d-flex justify-content-center">
    <h2 class="font liteGray">Contact Us</h2><br>
  </div>
</div>


<div class="container">
  <div class="row justify-content-center">
    <div class="font-roboto col-sm-12 col-md-4 foot-deets" style="display: flex; justify-content: center;">
      <div>
        <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
        <p><b>Tamara Basepayne</b><br><span>Director of Outdoor Education &<br>STEM Programs</span><br>Environmental
          Literacy<br>
          <a href="mailto:tbasepayne@sjcoe.net">tbasepayne@sjcoe.net </a><br> <a href="tel:2098178053">(209)
            817-8053</a></p>
      </div>
    </div>
    <div class="font-roboto col-sm-12 col-md-4" style="display: flex; justify-content: center;">
      <div>
        <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
        <p><b>Kristine Stepping</b><br>Outdoor Education and<br>Environmental Literacy<br>Coordinator<br>
          <a href="mailto:kstepping@sjcoe.net">kstepping@sjcoe.net</a> <br><a href="tel:2092922715">(209) 292-2715</a></p>
      </div>
    </div>
    <div class="font-roboto col-sm-12 col-md-4" style="display: flex; justify-content: center;">
      <div>
        <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
        <p><b>Lissa Gilmore</b><br>Principal<br><span>
            Outdoor Ed and Environmental Literacy<br>Sky Mountain</span><br>
          <a href="mailto:lgilmore@sjcoe.net">lgilmore@sjcoe.net</a> <br><a href="tel:5303892008">(530) 389-2008</a></p>
      </div>
    </div>
  </div>

  <!-- docial icons -->

  <div class="row mt-2">
    <div class="col d-flex justify-content-center iconGap">
      <a href="https://twitter.com/sjcoestem" target="_blank" rel="noopener" aria-label="twitter icon">
        <fa-icon [icon]="faTwitter" style="font-size: 37px; color: #165884;" alt="twitter icon"></fa-icon>
      </a>
      <a href="https://www.facebook.com/SJCOESTEM" target="_blank" rel="noopener" aria-label="faceBook icon">
        <fa-icon [icon]="faFacebookF" style="font-size: 32px; color: #165884;" alt="faceBook icon">
        </fa-icon>
      </a>

    </div>
  </div>

  <div class="col text-center">
    <a href="https://www.sjcoe.org/" target="_blank">
      <img src="assets/images/SJCOE-Formal.png" style="width:400px;">
    </a>

  </div>

</div><!-- end container -->

<div class="footer">
  <img src="assets/images/FooterBackground-01.png" alt="FooterBackground" style="width: 100%;">
</div>
