import { Component, OnInit, Input, INJECTOR } from '@angular/core';
import { Router } from '@angular/router';
import { faTwitter, faFacebookF, faInstagram,  } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  faAngelRight = faAngleRight;
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;

  constructor(private router: Router) { }
  
  ngOnInit(): void {
  //   this.c = this.contact;
  //   this.c.subscribe(
  //     x => {
  //       for (const s of contact ){
  //         const nFo: IcontactInfo = {

  //         } 
  //       }
  //     }
  //   )
  //   console.log(this.contact);
  // }

}
}
