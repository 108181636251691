import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Modules, Components } from './modu.e-helper';
import { CardComponent } from './components/card/card.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { SkyNavbarComponent } from './components/sky-navbar/sky-navbar.component';
import { JonesNavbarComponent } from './components/jones-navbar/jones-navbar.component';
import { DurhamNavbarComponent } from './components/durham-navbar/durham-navbar.component';
import { EventDeskmapComponent } from './components/event-deskmap/event-deskmap.component';
import { EventMobilemapComponent } from './components/event-mobilemap/event-mobilemap.component';
import { NgxSmoothScrollModule } from '@boatzako/ngx-smooth-scroll';
import { LongCardComponent } from './components/long-card/long-card.component';
import { DurhamFooterComponent } from './components/durham-footer/durham-footer.component';
import { SkyFooterComponent } from './components/sky-footer/sky-footer.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { DurhamLearnOutsideComponent } from './components/durham-learn-outside/durham-learn-outside.component';
import { DurhamWelcomeComponent } from './components/durham-welcome/durham-welcome.component';
import { DurhamFieldTripsComponent } from './components/durham-field-trips/durham-field-trips.component';
import { DurhamSummerCampComponent } from './components/durham-summer-camp/durham-summer-camp.component';
import { DurhamFacilityRentalsComponent } from './components/durham-facility-rentals/durham-facility-rentals.component';
import { DurhamProDevelopmentComponent } from './components/durham-pro-development/durham-pro-development.component';
import { DurhamHistoryComponent } from './components/durham-history/durham-history.component';
import { DurhamVolunteerComponent } from './components/durham-volunteer/durham-volunteer.component';




@NgModule({
  declarations: [
    CardComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    EventCardComponent,
    SkyNavbarComponent,
    JonesNavbarComponent,
    DurhamNavbarComponent,
    EventDeskmapComponent,
    EventMobilemapComponent,
    LongCardComponent,
    DurhamFooterComponent,
    SkyFooterComponent,
    ButtonsComponent,
    DurhamLearnOutsideComponent,
    DurhamWelcomeComponent,
    DurhamFieldTripsComponent,
    DurhamSummerCampComponent,
    DurhamFacilityRentalsComponent,
    DurhamProDevelopmentComponent,
    DurhamHistoryComponent,
    DurhamVolunteerComponent,
  ],
  imports: [
    CommonModule,
    NgxSmoothScrollModule,
    Modules
  ],
  exports: [
    Components,
    Modules
  ]
})
export class SharedModule { }
