<img src="{{contact.icon}}" alt="Contact Icon"><br>


<p *ngIf="contact.firstName == 'Amy'"><b>{{contact.firstName}} {{contact.lastName}}</b><br> {{contact.postion}} <br> {{contact.locationName}} <br> {{contact.title}} <br> {{contact.email}} <br>{{contact.address}} <br>{{contact.city}} {{contact.state}} {{contact.zip}}<br> {{contact.phoneNumber}}</p>


<p *ngIf="contact.firstName != '' && contact.firstName != 'Amy' && contact.faxNumber == '' && contact.locationName == ''"><b>{{contact.firstName}} {{contact.lastName}}</b><br> <span [innerHTML]="contact.postion"></span> <br> {{contact.email}} <br> {{contact.phoneNumber}}</p>


<p *ngIf="contact.locationName != '' && contact.title != '' && contact.firstName != 'Amy'"><b>{{contact.title}}</b> <br>{{contact.locationName}} <br>{{contact.address}}<br> {{contact.city}} {{contact.state}} {{contact.zip}}</p>


<p *ngIf="contact.locationName == '' && contact.title != ''"><b>{{contact.title}}</b> <br>{{contact.address}}<br> {{contact.city}} {{contact.state}} {{contact.zip}} <br> {{contact.phoneNumber}}</p>


<p *ngIf="contact.firstName == 'Allison'"><b>{{contact.firstName}} {{contact.lastName}}</b><br> {{contact.position}} <br> {{contact.email}} <br> {{contact.locationName}} <br>{{contact.address}} <br>{{contact.city}} {{contact.state}} {{contact.zip}}<br> {{contact.phoneNumber}} <br> {{contact.faxNumber}}</p>


<!-- <p *ngIf="contact.firstName == 'Lissa'"><b>{{contact.firstName}} {{contact.lastName}}</b><br> {{contact.postion}} <br> {{contact.locationName}} <br> {{contact.email}} <br> {{contact.phoneNumber}}</p> -->