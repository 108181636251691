<div class="container mt-5 text-center">
    <div class="row">
      <div class="col">
        <p class="sub-title">Facility Rentals</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="font-roboto" style="font-size: 16px;">Rent our outdoor pavilions, equestrian facility and classrooms.
          For more information, contact
          <a class="durhamLink underline" href="mailto:kstepping@sjcoe.net">kstepping@sjcoe.net</a></p>
      </div>
      <span class="FacilityRentals-img" alt="Facility rentals"></span>
    </div>
  </div>
