import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  faTimes = faTimes;
  public isMenuCollapsed = true;
  public visible = true;
  
  constructor(public router:Router) { }
  ngOnInit(): void {
  }
  navFunction(){
    this.visible = !this.visible; 
  }
}