import { Component, OnInit } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-durham-history',
  templateUrl: './durham-history.component.html',
  styleUrls: ['./durham-history.component.scss']
})
export class DurhamHistoryComponent implements OnInit {
  faAngelRight = faAngleRight;
  constructor() { }

  ngOnInit(): void {
  }
  readMore() {
    var x = document.getElementById("readMore");
    var y = document.getElementById("readLess");
    if (x.style.display === "none") {
      x.style.display = "inline";
      y.style.display = "none";
    } else {
      x.style.display = "none";
      y.style.display = "content";
    }
  }

}
