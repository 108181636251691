import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IEvent } from '../../models/ievent';
import { EventdataService } from '../../services/eventdata.service';

@Component({
  selector: 'app-durham-pro-development',
  templateUrl: './durham-pro-development.component.html',
  styleUrls: ['./durham-pro-development.component.scss']
})
export class DurhamProDevelopmentComponent implements OnInit {
  faAngelRight = faAngleRight;
  durhamProEventsArr: IEvent[] = [];
  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];
  displayedEventCards: IEvent[] = [];
  constructor(public router: Router, private _eventservice: EventdataService) { }

  ngOnInit(): void {
    this.getDurhamProfessinalEvents();
  }
  getDurhamProfessinalEvents() {
    // Clear Arrays
    this.durhamProEventsArr = [];
    this.showcasedCards = [];
    this.nonshowcasedCards = [];
    this.displayedEventCards = [];
    // Pull events from data service
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.durhamProEventsArr = res
        .filter(event => event.section_Pro_Dur === true && event.published === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });

        console.log(this.durhamProEventsArr);
      this.showcasedCards = this.durhamProEventsArr.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.durhamProEventsArr.filter(event => event.isShowcased === false);
      this.displayedEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    });
  }
  toEvent(eventCard: IEvent): void {
    if (eventCard.internal_event === true) {
      this.router.navigate(['/Event', eventCard.eventId]);
    } else if (eventCard.internal_event === false) {
      // window.location.href = eventCard.description_Long;
      window.open(eventCard.description_Long, "_blank")
    }
  }
}
