import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTwitter, faFacebookF, faInstagram,  } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-durham-footer',
  templateUrl: './durham-footer.component.html',
  styleUrls: ['./durham-footer.component.scss']
})
export class DurhamFooterComponent implements OnInit {
  faAngelRight = faAngleRight;
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
