import { Component, OnInit } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-durham-welcome',
  templateUrl: './durham-welcome.component.html',
  styleUrls: ['./durham-welcome.component.scss']
})
export class DurhamWelcomeComponent implements OnInit {

  faAngelRight = faAngleRight;
  constructor() { }

  ngOnInit(): void {
  }

}
