import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IEvent } from '../../models/ievent';
import { EventdataService } from '../../services/eventdata.service';

@Component({
  selector: 'app-durham-summer-camp',
  templateUrl: './durham-summer-camp.component.html',
  styleUrls: ['./durham-summer-camp.component.scss']
})
export class DurhamSummerCampComponent implements OnInit {
  durhamCampsArr: IEvent[] = [];
  durhamCampsNonshowcase: IEvent[] = [];
  durhamCampsShowcase: IEvent[] = [];
  durhamCampsDisplay: IEvent[] = [];
  faAngelRight = faAngleRight;
  
  constructor(public router: Router, private _eventservice: EventdataService) { }

  ngOnInit(): void {
    this.getDurhamSummerCamp();

  }
  getDurhamSummerCamp() {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.durhamCampsArr = res
        .filter(event => event.section_Student_durham=== true && event.section_Durham_Camp === true && event.published === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.durhamCampsShowcase = this.durhamCampsArr.filter(event => event.isShowcased === true);
      this.durhamCampsNonshowcase = this.durhamCampsArr.filter(event => event.isShowcased === false);
      this.durhamCampsDisplay = this.durhamCampsShowcase.concat(this.durhamCampsNonshowcase);
      console.log(this.durhamCampsDisplay);
    });
  }
  toEvent(eventCard: IEvent): void {
    eventCard.internal_event ? this.router.navigate(['/Event', eventCard.eventId]) : window.open(eventCard.description_Long, "_blank");
    // if (eventCard.internal_event === true) {
    //   this.router.navigate(['/Event', eventCard.eventId]);
    // } else if (eventCard.internal_event === false) {
    //   // window.location.href = eventCard.description_Long;
    //   window.open(eventCard.description_Long, "_blank")
    // }
  }
}
