<div class="row mt-5 ">
    <div class="col-xl-5 mb-5 d-flex justify-content-end fix-img">
      <img class="image-cropper" src="/assets/images/DurhamFerryCricleImg.png" alt="frog">
    </div>
    <div class="col-xl-5 mt-5\">
      <h2 class="sub-title">Let's Learn Outside</h2><br>
      <p class="small-font">Come explore our local environment at the Durham Ferry Outdoor Education Center located on
        the San Joaquin River in Manteca, CA. There are several miles of hiking trails, seasonal ponds, oak woodlands,
        various picnic areas, a working farm, and access to over 80 acres of wild riparian ecosystem. We offer student
        field trips, summer camps, and environmental literacy professional learning for teachers. Outdoor pavilions,
        classrooms, and equestrian facilities are available for rent!
      </p>
    </div>
  </div>