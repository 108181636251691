import { Component, OnInit } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-durham-volunteer',
  templateUrl: './durham-volunteer.component.html',
  styleUrls: ['./durham-volunteer.component.scss']
})
export class DurhamVolunteerComponent implements OnInit {

  faAngelRight = faAngleRight;
  
  constructor() { }

  ngOnInit(): void {
  }

}
