import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IcontactInfo } from '../../models/icontact-info';
import { contactLookup } from '../../lookups/contact-lookup';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor() { }
  contacts: BehaviorSubject<IcontactInfo[]> = new BehaviorSubject<IcontactInfo[]>(contactLookup.contactHomePage);
  
  setContacts(contact: IcontactInfo[]): void{
    this.contacts.next(contact);
  }
}
