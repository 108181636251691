<div class="row text-center mb-5 mt-5">
    <div class="col mb-2 d-flex justify-content-center">
      <h2 class="font liteGray">Contact Us</h2><br>
    </div>
  </div>
  
  
  <div class="container">
  <div class="row justify-content-center">
    <!--<div class="font-roboto col-sm-12 col-md-3 foot-deets">
  
      <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
      <p><b>Lissa Gilmore</b><br><span>Outdoor Education and <br> Environmental Literacy Coordinator</span><br>
        <a href="mailto:lgilmore@sjcoe.net">lgilmore@sjcoe.net </a><br> <a href="tel:209469170">(209) 468 - 9170</a></p>
  
    </div>-->
    <div class="font-roboto col-sm-12 col-md-3 foot-deets">
  
      <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
      <p><b>Kristine Stepping</b><br><span>Outdoor Education and <br> Environmental Literacy <br> Coordinator
          </span><br> <a href="mailto:kstepping@sjcoe.net">kstepping@sjcoe.net</a> <br><a href="tel:2092922715">(209) 292-2715</a></p>
  
    </div>
    <div class="font-roboto col-sm-12 col-md-3 foot-deets">
  
      <img alt="Contact Icon" src="assets/images/iconfinder_ic_contact_phone_48px_3669371.svg"><br>
      <p><b>Katie Coelho</b><br><span>Program Manager<br>Durham Ferry<br>
          </span><a href="mailto:kcoelho@sjcoe.net">kcoelho@sjcoe.net</a></p>
  
    </div>
    <div class="font-roboto col-sm-12 col-md-3 foot-deets">
  
      <img alt="Contact Icon" src="../../../assets/images/iconfinder_icon-map_211858.svg"><br>
      <p><b>Address:</b><br>Durham Ferry<br> Outdoor
        Education Center <br>29442 Airport Way<br> Manteca CA 95337</p>
    </div>
    <div class="font-roboto col-sm-12 col-md-3 foot-deets">
      <img alt="Contact Icon" src="../../../assets/images/iconfinder_icon-map_211858.svg"><br>
      <p><b>STEM Programs</b><br>PO Box 213030<br> Stockton CA 95213 <br> <a href="tel:2094684880">(209) 468-4880</a></p>
  
    </div>
  </div>
  
  <!-- docial icons -->
  
  <div class="row mt-2">
    <div class="col d-flex justify-content-center iconGap">
      <a href="https://twitter.com/sjcoestem" target="_blank" rel="noopener" aria-label="twitter icon">
        <fa-icon [icon]="faTwitter" style="font-size: 37px; color: #165884;" alt="twitter icon"></fa-icon>
      </a>
      <a href="https://www.facebook.com/SJCOESTEM" target="_blank" rel="noopener" aria-label="faceBook icon">
        <fa-icon [icon]="faFacebookF" style="font-size: 32px; color: #165884;" alt="faceBook icon">
        </fa-icon>
      </a>
  
    </div>
  </div>

  <div class="col text-center">
    <a href="https://www.sjcoe.org/" target="_blank">
      <img src="assets/images/SJCOE-Formal.png" style="width:400px;">
    </a>
  
  </div>
  
  </div><!-- end container -->
  
  <div class="footer">
    <img src="assets/images/FooterBackground-01.png" alt="FooterBackground" style="width: 100%;">
  </div>
