import { Component, OnInit, OnDestroy } from '@angular/core';
import { IcontactInfo } from './shared/models/icontact-info';
import { contactLookup } from './shared/lookups/contact-lookup';
import { ContactService } from './shared/components/contact/contact.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit , OnDestroy {
  constructor(public contactService: ContactService, private router: Router) {  }
  appSubscribe: Subscription = new Subscription();
  contacts: any
  title = 'outDoorEd';
  contactLookup: IcontactInfo[] = contactLookup.contactHomePage;

  ngOnInit(): void {

    const contactsSubscribe = this.contactService.contacts.subscribe(x => {
     this.contactLookup = x
     
    })
    this.appSubscribe.add(contactsSubscribe);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  }

  ngOnDestroy(): void {
    this.appSubscribe.unsubscribe();
  }
}
