import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { EventdataService } from '../../services/eventdata.service';
import { Icard } from '../../models/icard';
import { IEvent } from '../../models/ievent';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() card: Icard;
  faAngelRight = faAngleRight;
  durhamEventsArr: IEvent[] = [];
  skyMountainEventsArr: IEvent[] = [];
  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];
  displayedEventCards: IEvent[] = [];
  constructor(public router: Router, private _eventservice: EventdataService) { }
  ngOnInit(): void {
    // Clear Arrays
    this.durhamEventsArr = [];
    this.skyMountainEventsArr = [];
    this.showcasedCards = [];
    this.nonshowcasedCards = [];
    this.displayedEventCards = [];

    if (this.router.url.includes('/DurhamFerry')) {
      this.getDurhamEvents();
    }
    // else if (this.router.url.includes('/SkyMountain')) {
    //   this.getSkyMountainEvents();
    // }
  }
  getDurhamEvents() {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.durhamEventsArr = res
        .filter(event => event.section_Student_durham === true && event.published === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.durhamEventsArr.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.durhamEventsArr.filter(event => event.isShowcased === false);
      this.displayedEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    });
  }

  getSkyMountainEvents() {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.skyMountainEventsArr = res
        .filter(event => event.section_Student_durham === true && event.published === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.durhamEventsArr.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.durhamEventsArr.filter(event => event.isShowcased === false);
      this.displayedEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    });
  }

  toEvent(eventCard: IEvent): void {
    eventCard.internal_event ? this.router.navigate(['/Event', eventCard.eventId]) : window.open(eventCard.description_Long, "_blank");
    // if (eventCard.internal_event === true) {
    //   this.router.navigate(['/Event', eventCard.eventId]);
    // } else if (eventCard.internal_event === false) {
    //   // window.location.href = eventCard.description_Long;
    //   window.open(eventCard.description_Long, "_blank")
    // }
  }
}