<div class="container">
  <div class="row mt-4">
    <div class="col-12 fix-text text-center">
      <h1 class="dur-welcome white font-roboto pb-1">Welcome to </h1>
      <img class="durham-logo" src="../../../../assets/images/DurhamFerry_white.svg" alt="DurhamFerry Logo">
    </div>

    <div class="col text-center link-cont mb-3">
      <a href="https://www.sjcoe.org/" target="_blank">
        <img src="assets/images/SJCOELogoWhite2.svg" style="width:250px;">
      </a>
      
      </div>


  </div>
</div>